import { useEffect, useState } from 'react';
import MainLayout from '../../../layout/mainLayout';
import { getCampaingsListWithPagination } from '../../../service_config/admin.service';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import {
  BaeTableWeb,
  BaeTableMobile,
  BaePaginationWeb,
  BaeTableFilters,
  BaePaginationMobile,
} from '../../../components/Bae/BaeTables';
import {
  BaeHeading,
  BaeSmallText,
  BaeSuccessText,
} from '../../../components/Bae';

const STATUSES = ['all', 'active', 'inactive'];

const CampaignListView = () => {
  const navigate = useNavigate();
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [filters, setFilters] = useState({
    status: 'all',
  });

  const campaignListsWithPagination = useSelector(
    (state) => state.campaignAdd.campaignsListWithPagination
  );

  useEffect(() => {
    if (filters.status === 'all') {
      getCampaingsListWithPagination(page, limit);
    } else {
      getCampaingsListWithPagination(page, limit, filters.status);
    }
  }, [limit, page, filters]);

  const handleRowClick = (campaign) => {
    navigate(`/viewCampaigns/${campaign.id}`);
  };

  return (
    <MainLayout>
      <div className="md:p-6 p-4 md:m-6 bg-white md:border md:rounded-md shadow-md">
        <div className="mb-4">
          <BaeHeading>Campaigns</BaeHeading>
        </div>

        {campaignListsWithPagination?.campaigns?.length <= 0 && (
          <BaeSuccessText>
            <BaeSmallText>No Campaigns Found</BaeSmallText>
          </BaeSuccessText>
        )}

        {campaignListsWithPagination?.campaigns?.length > 0 && (
          <>
            <BaeTableFilters
              setLimit={setLimit}
              limit={limit}
              setFilters={setFilters}
              filters={filters}
              values={STATUSES}
            />
            <div className="hidden md:block">
              <BaeTableWeb
                headings={[
                  'Title',
                  'Description',
                  'Start Date',
                  'End Date',
                  'Active?',
                ]}
                paths={[
                  'title',
                  'description',
                  'start_date',
                  'end_date',
                  'is_active',
                ]}
                rows={campaignListsWithPagination.campaigns}
                pages={campaignListsWithPagination.pages}
                setPage={setPage}
                setLimit={setLimit}
                limit={limit}
                setFilters={setFilters}
                filters={filters}
                handleRowClick={handleRowClick}
              />
              <BaePaginationWeb
                pages={campaignListsWithPagination.pages}
                onPageChange={setPage}
              />
            </div>
            <div className="block md:hidden">
              <BaeTableMobile
                headings={[
                  'Title',
                  'Description',
                  'Start Date',
                  'End Date',
                  'Active?',
                ]}
                paths={[
                  'title',
                  'description',
                  'start_date',
                  'end_date',
                  'is_active',
                ]}
                rows={campaignListsWithPagination.campaigns}
                pages={campaignListsWithPagination.pages}
                setPage={setPage}
                setLimit={setLimit}
                limit={limit}
                setFilters={setFilters}
                filters={filters}
                handleRowClick={handleRowClick}
              />
              <BaePaginationMobile
                pages={campaignListsWithPagination.pages}
                onPageChange={setPage}
              />
            </div>
          </>
        )}
      </div>
    </MainLayout>
  );
};

export default CampaignListView;
