import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import MainLayout from '../../layout/mainLayout';

import { makeStyles } from '@material-ui/core';

import {
  FacebookIcon,
  FacebookShareButton,
  LinkedinIcon,
  LinkedinShareButton,
  TelegramIcon,
  TelegramShareButton,
  TwitterIcon,
  TwitterShareButton,
  WhatsappIcon,
  WhatsappShareButton,
} from 'react-share';
import {
  getBaeonnCredits,
  getUserDetails,
} from '../../service_config/admin.service';
import { Link, useNavigate } from 'react-router-dom';
import {
  BaeCopyTextInput,
  BaeHeading,
  BaeSmallText,
} from '../../components/Bae';

const useStyles = makeStyles((theme) => ({
  cards: {
    marginTop: '100px',
  },
  card: {
    width: '100%',
    padding: '50px',
    minHeight: '100vh',
  },
  imagediv: {
    width: '100%',
    position: 'absolute',
    top: '100px',
    display: 'flex',
    justifyContent: 'center',
    zIndex: '10',
  },
  image: {
    width: '150px',
    height: '100%',
    objectFit: 'cover',
  },
  count: {
    color: 'red',
    fontSize: '50px',
    fontWeight: 'bold',
  },
  link: {
    color: 'red',
    fontSize: '20px',
  },
  title: {
    fontSize: '25px',
    fontWeight: 'bold',
  },
  shareButton: {
    marginRight: '100px',
  },
}));

export default function CreditAndRef() {
  const navigate = useNavigate();
  const classes = useStyles();
  const data = useSelector((state) => state.userDetail.userDetail);
  const [credits, setCredits] = useState(0);
  const [businessId, setBusinessId] = useState(null);

  const GETURL = () => {
    return `${process.env.REACT_APP_WEB_BASE_URL}login?refUser=${data?.id}&refCode=${data?.referralCode}`;
  };

  const title = 'Acquire and retain customers at ease with BAEON.';
  const hashtag = 'baeon';

  React.useEffect(() => {
    getUserDetails()
      .then((res) => {
        setBusinessId(res.result.businessId);
      })
      .catch((err) => {
        // eslint-disable-next-line prettier/prettier, no-console
        console.log(err);
      });
  }, []);

  React.useEffect(() => {
    if (!businessId) return;
    getBaeonnCredits(businessId, 1, 1, 'all')
      .then((res) => {
        setCredits(res.result.total_remaining);
      })
      .catch((_) => {
        //
      });
  }, [businessId]);

  return (
    <>
      <MainLayout>
        <div className="md:p-6 p-4 md:m-6 bg-white md:border md:rounded-md shadow-md">
          <BaeHeading>Credit and Referral</BaeHeading>

          <div className="mt-6"></div>

          <div className="mt-6">
            <BaeSmallText>Total Point Earned</BaeSmallText>
            <p className="text-4xl text-baeonn-800 font-extrabold">{credits}</p>
            <Link
              to="/credit/transactions"
              className="text-baeonn-800 hover:underline"
            >
              See more
            </Link>
          </div>

          <div className="mt-6">
            <BaeSmallText>
              Your referral link, share with your friends and earn baeonn
              credits
            </BaeSmallText>
            <BaeCopyTextInput text={GETURL()} />
          </div>

          {/* shaer via buttons */}
          <div className="mt-6">
            <BaeHeading>Social media buttons</BaeHeading>
          </div>

          <div className="grid grid-cols-2 md:grid-cols-3 gap-4 mt-6">
            <div className="flex justify-center">
              <FacebookShareButton
                url={GETURL()}
                hashtag={hashtag}
                quote={title}
                className="shareButton "
              >
                <FacebookIcon size={100} round />
              </FacebookShareButton>
            </div>
            <div className="flex justify-center">
              {' '}
              <TwitterShareButton
                url={GETURL()}
                hashtags={[hashtag]}
                title={title}
                className="shareButton "
              >
                <TwitterIcon size={100} round />
              </TwitterShareButton>
            </div>
            <div className="flex justify-center">
              {' '}
              <TelegramShareButton
                url={GETURL()}
                title={title}
                className="shareButton"
              >
                <TelegramIcon size={100} round />
              </TelegramShareButton>
            </div>
            <div className="flex justify-center">
              {' '}
              <WhatsappShareButton
                url={GETURL()}
                title={title}
                separator=":: "
                className="shareButton"
              >
                <WhatsappIcon size={100} round />
              </WhatsappShareButton>
            </div>
            <div className="flex justify-center">
              <LinkedinShareButton url={GETURL()} className="shareButton">
                <LinkedinIcon size={100} round />
              </LinkedinShareButton>
            </div>
          </div>
        </div>
      </MainLayout>
    </>
  );
}
