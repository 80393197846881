import CreateCampaign from './CreateCampaign';
import MainLayout from '../../../layout/mainLayout';

import React, { useState } from 'react';
import { CampaignActivation } from './CampaignActivation';
import CampaignComplete from './CampaignComplete';
import { useNavigate } from 'react-router-dom';

const BreadcrumbSteps = ({ step }) => {
  const selectedClass = 'text-baeonn-600 dark:text-baeonn-400';
  const defaultClass = 'text-gray-500 dark:text-gray-400';

  return (
    <nav className="flex" aria-label="Breadcrumb">
      <ol className="inline-flex items-center space-x-2 rtl:space-x-reverse">
        <li
          className={`cursor-not-allowed inline-flex items-center text-sm font-medium ${step === 1 ? selectedClass : defaultClass}`}
        >
          Campaign
        </li>

        <svg
          className="rtl:rotate-180 w-3 h-3 text-gray-400 mx-1"
          aria-hidden="true"
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 6 10"
        >
          <path
            stroke="currentColor"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
            d="m1 9 4-4-4-4"
          />
        </svg>

        <li
          className={`cursor-not-allowed ms-1 text-sm font-medium  md:ms-2 ${step === 2 ? selectedClass : defaultClass}`}
        >
          Payment
        </li>

        <svg
          className="rtl:rotate-180 w-3 h-3 text-gray-400 mx-1"
          aria-hidden="true"
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 6 10"
        >
          <path
            stroke="currentColor"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
            d="m1 9 4-4-4-4"
          />
        </svg>

        <li
          className={`cursor-not-allowed ms-1 text-sm font-medium  md:ms-2 ${step === 3 ? selectedClass : defaultClass}`}
        >
          Complete
        </li>
      </ol>
    </nav>
  );
};

const CampaignCreateProcess = () => {
  const navigate = useNavigate();
  const [step, setStep] = useState(1);
  const [campaign_id, setCampaignId] = useState('');

  const nextStep = (data) => {
    setStep(step + 1);
    if (data?.campaign_id?.length > 0) {
      setCampaignId(data.campaign_id);
      /**
       * done purposefully to navigate to the campaign activation page
       * after the campaign creation is successful
       */
      navigate(`/campaign-activation/${data.campaign_id}`);
    }
  };

  return (
    <MainLayout>
      <div className="md:p-6 p-4 md:m-6 bg-white md:border md:rounded-md shadow-md">
        <div className="flex justify-start md:justify-center pb-2">
          {/* <BreadcrumbSteps step={step} /> */}
        </div>

        {step === 1 && <CreateCampaign nextStepHandler={nextStep} />}
        {step === 2 && (
          <CampaignActivation
            campaign_id={campaign_id}
            nextStepHandler={nextStep}
          />
        )}
        {step === 3 && <CampaignComplete />}
      </div>
    </MainLayout>
  );
};

export default CampaignCreateProcess;
