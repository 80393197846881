import { getCurrentUser } from '../service_config/auth.service';
import { get, patch, post, put } from '../service_config/http.service';

import {
  campaignMediaPartnerReducer,
  campaignsListWithPaginationReducer,
  couponListReducer,
  creditReducer,
  invitesStoresReducer,
  mediaPartnerReducer,
  multiplestoresReducer,
  store,
  userReducer,
  viewCampaignReducer,
  viewCouponReducer,
} from '../service_config/configStore';

export const generateQrCode = async (data) => {
  try {
    const res = await post('/generate-qr', data);
    return res.data;
  } catch (e) {
    return { success: false, error: e.message };
  }
};

export const isNotLoading = () => {
  const pageLoader = document.getElementById('pageLoader');
  if (pageLoader && pageLoader.classList.contains('block')) {
    pageLoader.classList.remove('block');
  }
};

export const isLoading = () => {
  document.getElementById('pageLoader').classList.add('block');
  setTimeout(() => {
    isNotLoading();
  }, 5000);
};

export const submitBusinessOwnerDetails = async (data) => {
  try {
    const res = await post(`/users/${getCurrentUser().roleId}/business`, {
      ...data,
    });
    return res.data;
  } catch (e) {
    return e.response.data;
  }
};
export const submitBusinessDetail = async (data) => {
  try {
    const res = await put(
      `/users/${getCurrentUser().id}/business-detail`,
      data
    );
    return res.data;
  } catch (e) {
    return e.response.data;
  }
};

export const submitBankDetail = async (data) => {
  try {
    let res = await put(`/users/${getCurrentUser().id}/bank-detail`, data);
    return res.data;
  } catch (e) {
    return e.response.data;
  }
};

export const updateStoreSubmit = async (data) => {
  try {
    const res = await put(`/store/${data.id}`, data.store);
    return res.data;
  } catch (e) {
    return e.response.data;
  }
};

export const getStoreDetail = async (data) => {
  isLoading();
  try {
    const res = await get(`/stores/`);
    store.dispatch(multiplestoresReducer(res.data.result));
    isNotLoading();
    return res.data;
  } catch (e) {
    isNotLoading();
    return e.response.data;
  }
};
export const getStoreDetailByPhoneNumber = async (data) => {
  try {
    const res = await get(
      `/stores/getStores/${getCurrentUser()?.mobileNumber}`
    );
    store.dispatch(multiplestoresReducer(res.data.result));
    return res.data;
  } catch (e) {
    return e.response.data;
  }
};
export const getDealPoolCamaignByStoreId = async (storeId) => {
  try {
    const res = await get(`/campaigns/dealPool?storeId=${storeId}`);
    return res.data;
  } catch (e) {
    return e.response.data;
  }
};

export const getStoreDetailforManager = (data) => {
  get(`/stores/${data.id}`).then((res) => {
    store.dispatch(multiplestoresReducer([res.data.result]));
  });
};
export const getInviteStore = () => {
  get(`/stores/invites`).then((res) => {
    store.dispatch(invitesStoresReducer(res.data.result));
  });
};

export const reSendInviteStore = async (data) => {
  try {
    const res = await patch(`stores/resend-invites`, data.body);
    return res.data;
  } catch (e) {
    return e.response.data;
  }
};
export const deleteSendInviteStore = async (data) => {
  try {
    const res = await patch(`stores/delete-invites`, data.body);
    return res.data;
  } catch (e) {
    return e.response.data;
  }
};
export const getStoreDetailById = async (data) => {
  try {
    const res = await get(`/stores/${data.id}`);
    return res.data;
  } catch (e) {
    return e.response.data;
  }
};

export const updateStoreDetailById = async (data) => {
  try {
    const res = await put(`/stores/${data.id}`, data.body);
    return res.data;
  } catch (e) {
    return e.response.data;
  }
};
export const submitStoreDetail = async (data) => {
  try {
    const res = await post(`/stores`, data);
    return res.data;
  } catch (e) {
    return e.response.data;
  }
};
export const getNearBuyStores = async (data) => {
  try {
    const res = await get(`/stores/nearbyStores/${data.pincode}`);
    return res.data;
  } catch (e) {
    return e.response.data;
  }
};
export const getBussinessDetail = async () => {
  try {
    const res = await get(`/users/${getCurrentUser().id}/business-detail`);
    return res.data;
  } catch (error) {
    return error.response.data;
  }
  // get(`/users/${getCurrentUser().id}/business-detail`).then((res) => {
  //   store.dispatch(businessDetailReducer({...res.data.result}));
  // });
};
export const getBankDetail = async (data) => {
  try {
    const res = await get(`/users/${getCurrentUser().id}/bank-detail`);
    return res.data;
  } catch (e) {
    return e.response.data;
  }
};
export const getUserDetails = async (data) => {
  try {
    const res = await get(`/users/${getCurrentUser().id}`);
    store.dispatch(userReducer({ ...res.data.result }));
    return res.data;
  } catch (e) {
    return e.response.data;
  }
};

export const getCredit = () => {
  get(`/users/${getCurrentUser().id}`)
    .then((result) => {
      store.dispatch(creditReducer({ ...result.data.result }));
    })
    .catch((err) => err.response.data);
};

export const getCreditPoints = async (data) => {
  try {
    const res = await get(`/users/${getCurrentUser().id}/business-detail`);
    return res.data;
  } catch (e) {
    return e.response.data;
  }
};

export const getUserStore = () => {
  return get(`/users/${getCurrentUser().id}/store`)
    .then((response) => {
      store.dispatch(
        viewCampaignReducer({
          storeId: response.data.payload.id,
          branchName: response.data.payload.branchName,
        })
      );
      if (response.data.payload.id) {
        getStoreCampaign(response.data.payload.id);
      }
    })
    .catch((err) => err.response.data);
};

export const getStoreCampaign = (storeId) => {
  return get(`/store/${storeId}/campaign-own`)
    .then((response) => {
      store.dispatch(
        viewCampaignReducer({ viewDetails: response.data.payload })
      );
    })
    .catch((err) => err.response.data);
};

export const shareCoupon = async (data) => {
  try {
    const res = await patch(`/coupons/${data.id}/share`, { ...data.data });
    return res.data;
  } catch (e) {
    return e.response.data;
  }
};

export const claimCoupon = async (data) => {
  try {
    const res = await patch(`/coupons/claim`, { ...data });
    return res;
  } catch (e) {
    return e.response;
  }
};
export const createCampaign = async (data) => {
  isLoading();
  try {
    const res = await post(`/campaigns`, { ...data });
    isNotLoading();
    return res.data;
  } catch (e) {
    isNotLoading();
    return e.response.data;
  }
};

export const createDealPoolCampaign = async (data) => {
  isLoading();
  try {
    const res = await post(`/campaigns/dealPool`, { ...data });
    isNotLoading();
    return res.data;
  } catch (e) {
    isNotLoading();
    return e.response.data;
  }
};
export const viewCampaignlist = async () => {
  isLoading();
  try {
    const res = await get(`/campaigns`);
    store.dispatch(viewCampaignReducer(res.data.result));
    isNotLoading();
    return res.data;
  } catch (e) {
    isNotLoading();
    return e.response.data;
  }
};
export const viewAssigedCampaignlist = async () => {
  isLoading();
  try {
    const res = await get(`/campaigns/assigned`);
    store.dispatch(viewCampaignReducer(res.data.result));
    isNotLoading();
    return res.data;
  } catch (e) {
    isNotLoading();
    return e.response.data;
  }
};
export const viewCouponlist = async () => {
  isLoading();
  try {
    const res = await get(`/coupons`);
    store.dispatch(viewCouponReducer(res.data.result));
    isNotLoading();
    return res.data;
  } catch (e) {
    isNotLoading();
    return e.response.data;
  }
};
export const viewCouponlistforManger = async () => {
  isLoading();
  try {
    const res = await get(`/coupons?storeId=${getCurrentUser().storeId}`);
    store.dispatch(viewCouponReducer(res.data.result));
    isNotLoading();
    return res.data;
  } catch (e) {
    isNotLoading();
    return e.response.data;
  }
};
export const getMediaPartner = async (data) => {
  isLoading();
  try {
    const res = await get(`/media-partners?storeId=${data.storeId}`);
    store.dispatch(mediaPartnerReducer(res?.data?.result));
    isNotLoading();
    return res.data;
  } catch (e) {
    isNotLoading();
    return e?.response?.data;
  }
};
export const getShareCouponListDealPool = async (storeId) => {
  isLoading();
  try {
    const res = await get(
      `/campaigns/assigned?isDealPool=true&storeId=${storeId}`
    );
    isNotLoading();
    return res.data;
  } catch (e) {
    isNotLoading();
    return e.response.data;
  }
};

export const getShareCouponList = async () => {
  isLoading();
  try {
    const res = await get(`/campaigns/assigned`);
    isNotLoading();
    store.dispatch(couponListReducer(res.data.result));
    return res.data;
  } catch (e) {
    isNotLoading();
    return e.response.data;
  }
};
export const getCategoryLists = async () => {
  isLoading();
  try {
    const res = await get(`/store-categories`);
    isNotLoading();
    return res.data;
  } catch (e) {
    isNotLoading();
    return e.response.data;
  }
};
export const onGenerateCoupons = async (data) => {
  try {
    const res = await post(`/coupons`, { ...data });
    return res;
  } catch (e) {
    return e.response;
  }
};

export const paymentInfo = async (data) => {
  try {
    const res = await post(`/campaigns/${data.campaignId}/quote`, {
      ...data.payload,
    });
    // store.dispatch(addPaymentReducer(res.data.payload));
    return res;
  } catch (e) {
    return e.response;
  }
};
export const updateCouponQr = async (data) => {
  try {
    const res = await post(`/users//get-coupon/qr`, data.payLoad);
    return res;
  } catch (e) {
    return e.response;
  }
};
export const makePaymentWithoutFee = async (data) => {
  try {
    const res = await post(`/campaigns/${data.campaignId}/pay `);
    // store.dispatch(addPaymentReducer(res.data.payload));
    return res;
  } catch (e) {
    return e.response;
  }
};
export const inviteStore = async (data) => {
  isLoading();
  try {
    const res = await post(`stores/invite`, { ...data });
    isNotLoading();
    return res.data;
  } catch (e) {
    isNotLoading();
    return e.response.data;
  }
};
export const getEstimateFee = async (data) => {
  isLoading();
  try {
    const res = await post(`/campaigns/estimate-fee`, { ...data });
    isNotLoading();
    return res.data;
  } catch (e) {
    isNotLoading();
    return e.response.data;
  }
};
export const getStateList = async (data) => {
  isLoading();
  try {
    const res = await post(`/countries/IND`, { ...data });
    isNotLoading();
    return res.data;
  } catch (e) {
    isNotLoading();
    return e.response.data;
  }
};
export const getAssignedStoresCampaigns = async (data) => {
  try {
    const res = await get(`/stores/nearbyStores/${data.pincode}`);
    return res.data;
  } catch (e) {
    return e.response.data;
  }
};
export const getCampaingnByQR = async (data) => {
  try {
    const res = await get(`/stores/${data?.storeId}/qr`);
    return res.data;
  } catch (e) {
    return e.response.data;
  }
};

// --------------------------------------inputfieldError-------------------------------------------------

export const validateInputs = (obj) => {
  const items = Object.keys(obj);
  const errors = {};
  items.map((key) => {
    const value = obj[key];
    if (!value) {
      errors[key] = key + ' is required';
    }
  });
  return errors;
};

export const findErrors = (obj) => {
  const items = Object.keys(obj);
  const errors = [];
  items.map((key) => {
    const value = obj[key];
    if (!value) {
      errors.push(key);
    }
  });
  if (errors.length > 0) {
    return true;
  } else {
    return false;
  }
};

export const getCampaignMediaPartner = async (storeId, campaignType) => {
  isLoading();
  try {
    if (!storeId) {
      throw new Error('Store Id is required');
    }
    const res = await get(
      `/media-partners/campaign-media-partners?store_id=${storeId}&campaign_type=${campaignType}`
    );
    store.dispatch(campaignMediaPartnerReducer(res?.data?.result));
    isNotLoading();
    return res.data;
  } catch (e) {
    isNotLoading();
    return e?.response?.data;
  }
};

export const getCamapignPaymentQuote = async (campaign_id, credits = 0) => {
  isLoading();
  try {
    const res = await get(
      `/payment/quote?campaign_id=${campaign_id}&credits=${credits}`
    );
    isNotLoading();
    return res.data;
  } catch (e) {
    isNotLoading();
    return e.response.data;
  }
};

export const activateCampaign = async (campaign_id, credits = 0) => {
  isLoading();
  try {
    const res = await post(`campaigns/${campaign_id}/activate`, { credits });
    isNotLoading();
    return res.data;
  } catch (e) {
    isNotLoading();
    throw e;
  }
};

export const getCampaingsListWithPagination = async (
  page = 1,
  limit = 10,
  status = 'all'
) => {
  isLoading();
  const query = status === 'all' ? '' : `&status=${status}`;
  try {
    const res = await get(
      `/campaigns/get-campaigns?page=${page}&limit=${limit}${query}`
    );
    store.dispatch(campaignsListWithPaginationReducer(res.data.result));
    isNotLoading();
    return res.data;
  } catch (e) {
    isNotLoading();
    throw e;
  }
};

export const getCampaignByIdV2 = async (campaign_id) => {
  isLoading();
  try {
    const res = await get(`/campaigns/${campaign_id}?v2=${true}`);
    isNotLoading();
    return res.data;
  } catch (e) {
    isNotLoading();
    throw e;
  }
};

export const updateCampaignById = async (campaign_id, data = {}) => {
  isLoading();
  try {
    const res = await put(`/campaigns/${campaign_id}`, data);
    isNotLoading();
    return res.data;
  } catch (e) {
    isNotLoading();
    throw e;
  }
};

export const getBaeonnCredits = async (business_id, page, limit, status) => {
  isLoading();
  try {
    const res = await get(
      `/payment/baeonn-credits?business_id=${business_id}&page=${page}&limit=${limit}&status=${status}`
    );
    isNotLoading();
    return res.data;
  } catch (e) {
    isNotLoading();
    throw e;
  }
};

export const getBaeonnWallet = async (business_id, page, limit, status) => {
  isLoading();
  try {
    const res = await get(
      `/payment/baeonn-wallet?business_id=${business_id}&page=${page}&limit=${limit}&status=${status}`
    );
    isNotLoading();
    return res.data;
  } catch (e) {
    isNotLoading();
    throw e.data;
  }
};

export const getCanActivateCampaign = async (campaign_id, credit) => {
  isLoading();
  try {
    const res = await get(
      `/payment/can-activate?campaign_id=${campaign_id}&credit=${credit}`
    );
    isNotLoading();
    return res.data;
  } catch (e) {
    isNotLoading();
    throw e;
  }
};

export const getCoupons = async (storeId, type, businessId) => {
  isLoading();
  try {
    const res = await get(
      `/stores/${storeId}/coupon?type=${type}&business_id=${businessId}`
    );
    isNotLoading();
    return res.data;
  } catch (e) {
    isNotLoading();
    throw e.response.data;
  }
};

export const shareCoupons = async (data) => {
  isLoading();
  try {
    const res = await post(`/coupons/share`, data);
    isNotLoading();
    return res.data;
  } catch (e) {
    isNotLoading();
    throw e.response.data;
  }
};

export const claimCoupons = async ({ coupon_code, mobile_number, otp }) => {
  isLoading();
  try {
    const payload = { coupon_code, mobile_number };
    if (otp) {
      payload.otp = otp;
    }
    const res = await post(`/coupons/claim`, payload);
    isNotLoading();
    return res.data;
  } catch (e) {
    isNotLoading();
    throw e.response.data;
  }
};

export const getStoreAnalytics = async (storeId) => {
  isLoading();
  try {
    const res = await get(`/analytics/store/${storeId}`);
    isNotLoading();
    return res.data;
  } catch (e) {
    isNotLoading();
    throw e.response.data;
  }
};

export const getCityOrStates = async (state_id = null, load = true) => {
  load && isLoading();
  try {
    if (state_id) {
      const res = await get(`/city/cities?state_id=${state_id}`);
      isNotLoading();
      return res.data;
    } else {
      const res = await get(`/city/states`);
      isNotLoading();
      return res.data;
    }
  } catch (e) {
    isNotLoading();
    throw e.response.data;
  }
};

export const getCityStateByPincode = async (pincode, load = true) => {
  load && isLoading();
  try {
    const res = await get(`/city/pincode?pincode=${pincode}`);
    isNotLoading();
    return res.data;
  } catch (e) {
    isNotLoading();
    throw e.response.data;
  }
};
