import React, { useEffect } from 'react';
import QuickAction from './quickAction';
import MainLayout from '../../../layout/mainLayout';
import { useSelector } from 'react-redux';
import BaeDropdown from '../../../components/Bae/BaeDropdown';
import { BaeDetailBox, BaeHeading } from '../../../components/Bae';
import { getStoreAnalytics } from '../../../service_config/admin.service';
import { toast } from 'react-toastify';

const Analytics = () => {
  const stores = useSelector((state) => state.storeDetail.stores);
  const [campaignForStore, setCampaignForStore] = React.useState(null);
  const [storeAnalytics, setStoreAnalytics] = React.useState(null);

  useEffect(() => {
    if (campaignForStore?.id) {
      getStoreAnalytics(campaignForStore?.id)
        .then((res) => {
          setStoreAnalytics(res.result);
        })
        .catch((_) => {
          toast.error('Failed to fetch store analytics');
        });
    }
  }, [campaignForStore]);

  return (
    <MainLayout>
      <div
        className="md:p-6 p-4 md:m-6 bg-white md:border md:rounded-md shadow-md"
        style={{ fontFamily: 'Inter, "Noto Sans", sans-serif' }}
      >
        <div className="p-4 @container">
          <BaeHeading>Business Dashboard</BaeHeading>
        </div>

        <div className="p-4 @container">
          <BaeDropdown
            rows={stores}
            field={'branchName'}
            callback={(store) => setCampaignForStore(store)}
            initialSelectedPos={0}
          />
        </div>

        <div className="p-4 @container">
          <div className="grid grid-cols-4 md:grid-cols-3 gap-4">
            <div className="col-span-4 md:col-span-1">
              <BaeDetailBox
                title="Total Earnings"
                value={`${storeAnalytics?.currency || ''}${storeAnalytics?.earnings?.total || 0}`}
              />
            </div>
            <div className="col-span-2 md:col-span-1">
              <BaeDetailBox
                title="Earnings from Sharing"
                value={`${storeAnalytics?.currency || ''}${storeAnalytics?.earnings?.sharing || 0}`}
              />
            </div>
            <div className="col-span-2 md:col-span-1">
              <BaeDetailBox
                title="Earnings from Redemptions"
                value={`${storeAnalytics?.currency || ''}${storeAnalytics?.earnings?.claim || 0}`}
              />
            </div>
          </div>
        </div>

        <div className="p-4 @container">
          <QuickAction />
        </div>

        <div className="p-4 @container">
          <h2 className="text-black text-[22px] font-bold leading-tight tracking-[-0.015em] pb-3">
            Campaign Performance
          </h2>

          <div className="flex flex-wrap gap-4">
            <div className="flex-1 flex-col gap-2">
              <BaeDetailBox
                title="Coupons Shared"
                value={storeAnalytics?.coupons?.shared || 0}
              />
            </div>
            <div className="flex-1 flex-col gap-2">
              <BaeDetailBox
                title="Coupons Redeemed"
                value={storeAnalytics?.coupons?.claimed || 0}
              />
            </div>
          </div>
        </div>

        {/* <div className="p-4 @container">
          <AnalyticsGraph />
        </div>

        <div className="p-4 @container">
          <RecentActivity />
        </div> */}
      </div>
    </MainLayout>
  );
};

export default Analytics;
