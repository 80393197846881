import { useEffect, useState } from 'react';
import { BaeHeading, BaeTab } from '../../../components/Bae';
import MainLayout from '../../../layout/mainLayout';
import BusinessDetails from './BusinessDetails';
import BankDetails from './BankDetails';

const Business = () => {
  const tabs = ['Business', 'Bank'];
  const [selectedTab, setSelectedTab] = useState('Business');
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <MainLayout>
      <div className="md:p-6 p-4 md:m-6 bg-white md:border md:rounded-md shadow-md">
        {isMobile ? (
          <div className="md:hidden" key={1}>
            <BaeHeading>Business & Bank Details</BaeHeading>
            <div className="mt-4"></div>
            <div>
              <div>
                <BaeTab
                  tabs={tabs}
                  onSelect={(tab) => {
                    setSelectedTab(tab);
                  }}
                  isSpaced={true}
                />
              </div>

              {selectedTab === 'Business' ? (
                <div>
                  <BusinessDetails />
                </div>
              ) : (
                <div>
                  <BankDetails />
                </div>
              )}
            </div>
          </div>
        ) : (
          <div className="hidden md:block" key={1}>
            <BaeHeading>Business Details</BaeHeading>
            <div className="grid grid-cols-1 md:grid-cols-12 md:gap-20">
              <div className="col-span-6">
                <BusinessDetails />
              </div>
              <div className="col-span-6 md:mt-0 mt-6">
                <BankDetails />
              </div>
            </div>
          </div>
        )}
      </div>
    </MainLayout>
  );
};

export default Business;
