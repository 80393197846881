import { Box, Typography } from '@material-ui/core';
import LinearProgress from '@mui/material/LinearProgress';
import React from 'react';
import { toast } from 'react-toastify';
import { FILE_UPLOAD } from '../service_config/http.service';

//this is HOC for file uploading module

const FileUploadHoc = ({ children, fileType, set: setImage, storeId }) => {
  const [progress, setProgress] = React.useState(0);

  React.useEffect(() => {
    document.getElementById(fileType).onchange = upload;
  }, []);

  //this function will trigger file upload
  const upload = async (e) => {
    e.preventDefault();
    const {
      target: { files },
    } = e; //target comming from e, files from e.target.files
    //target comming from e, files from e.target.files
    let data = new FormData();
    data.append('file', files[0]);

    const options = {
      onUploadProgress: (progressEvent) => {
        const { loaded, total } = progressEvent;
        let percentage = Math.round((loaded * 100) / total);
        if (percentage < 99) setProgress(percentage);
      },
      'content-type': files[0].type,
    };

    //upload http api
    try {
      const response = await FILE_UPLOAD(
        `/file-upload?fileType=${fileType}&store_id=${storeId}`,
        data,
        options
      );
      if (response.data.error) {
        toast.error(response.data.result.msg);
        setProgress(0);
        return;
      }
      setProgress(100);
      setImage && setImage(response.data.result.locationUrl, fileType);
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log('file up err', error);
      toast.error(error.response.data.result.msg);
      setProgress(0);
    }
  };

  return (
    <>
      {children}
      {progress === 0 || progress === 100 ? null : (
        <>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Box sx={{ width: '100%', mr: 1 }}>
              <LinearProgress variant="determinate" value={progress} />
            </Box>
            <Box sx={{ minWidth: 35 }}>
              <Typography variant="body2" color="text.secondary">{`
        ${progress}%`}</Typography>
            </Box>
          </Box>
        </>
      )}
    </>
  );
};

export default FileUploadHoc;
