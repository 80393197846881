import { useEffect, useState } from 'react';
import {
  BaeTableWeb,
  BaeTableMobile,
  BaeTableFilters,
  BaePaginationWeb,
  BaePaginationMobile,
} from '../../../components/Bae/BaeTables';
import MainLayout from '../../../layout/mainLayout';
import { getBaeonnCredits } from '../../../service_config/admin.service';
import { getUserDetails } from '../../../service_config/admin.service';
import {
  BaeHeading,
  BaeHorizontalLine,
  BaeSmallText,
  BaeWarningText,
} from '../../../components/Bae';

const STATUSES = ['all', 'credit', 'debit'];

const OverallView = ({ totalCredits, totalDebits, totalBalance }) => {
  return (
    <div className="grid grid-cols-3 gap-4">
      <div>
        <BaeSmallText className={'text-green-800 block md:inline'}>
          Total Credits:{' '}
        </BaeSmallText>
        <BaeSmallText>{totalCredits}</BaeSmallText>
      </div>
      <div>
        <BaeSmallText className={'text-red-800 block md:inline'}>
          Total Debits:{' '}
        </BaeSmallText>
        <BaeSmallText>{totalDebits}</BaeSmallText>
      </div>
      <div>
        <BaeSmallText className={'text-baeonn-800 block md:inline'}>
          Total Balance:{' '}
        </BaeSmallText>
        <BaeSmallText>{totalBalance}</BaeSmallText>
      </div>
    </div>
  );
};

const IfCredis = ({
  totalCredits,
  totalDebits,
  totalBalance,
  setLimit,
  limit,
  setFilters,
  filters,
  credits,
  handleRowClick,
  pages,
  setPage,
}) => {
  return (
    <div>
      {/* show on mobile, desktop is shown on bottom */}
      <div className="md:hidden">
        <OverallView
          totalCredits={totalCredits}
          totalDebits={totalDebits}
          totalBalance={totalBalance}
        />
        <BaeHorizontalLine />
      </div>

      <div>
        <BaeTableFilters
          setLimit={setLimit}
          limit={limit}
          setFilters={setFilters}
          filters={filters}
          values={STATUSES}
        />
      </div>

      <div className="hidden md:block">
        <BaeTableWeb
          rows={credits}
          headings={['Type', 'Amount', 'Date', 'Time', 'TID']}
          paths={['type', 'amount', 'date', 'time', 'transaction_id']}
          handleRowClick={handleRowClick}
        />
        <BaePaginationWeb pages={pages} onPageChange={setPage} />
      </div>
      <div className="block md:hidden">
        <BaeTableMobile
          rows={credits}
          headings={['Type', 'Amount', 'Date', 'Time', 'TID']}
          paths={['type', 'amount', 'date', 'time', 'transaction_id']}
          handleRowClick={handleRowClick}
        />
        <BaePaginationMobile pages={pages} onPageChange={setPage} />
      </div>

      <div className="hidden md:block">
        <BaeHorizontalLine />
      </div>

      {/* show on desktop, mobile is shown on top */}
      <div className="hidden md:block">
        <OverallView
          totalCredits={totalCredits}
          totalDebits={totalDebits}
          totalBalance={totalBalance}
        />
      </div>
    </div>
  );
};

const BaeonnCredits = () => {
  const [businessId, setBusinessId] = useState([]);
  const [credits, setCredits] = useState([]);
  const [totalCredits, setTotalCredits] = useState([]);
  const [totalDebits, setTotalDebits] = useState([]);
  const [totalBalance, setTotalBalance] = useState([]);
  const [pages, setPages] = useState([]);
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [filters, setFilters] = useState({
    status: 'all',
  });

  useEffect(() => {
    getUserDetails()
      .then((res) => {
        setBusinessId(res.result.businessId);
      })
      .catch((err) => {
        // eslint-disable-next-line prettier/prettier, no-console
        console.log(err);
      });
  }, []);

  useEffect(() => {
    getBaeonnCredits(businessId, page, limit, filters.status)
      .then((res) => {
        setCredits(res.result.credits);
        setTotalCredits(res.result.total_credit);
        setTotalDebits(res.result.total_debit);
        setTotalBalance(res.result.total_remaining);
        setPages(res.result.pages);
      })
      .catch((err) => {
        // eslint-disable-next-line prettier/prettier, no-console
        console.log(err);
      });
  }, [businessId, page, limit, filters]);

  // eslint-disable-next-line prettier/prettier
  const handleRowClick = (campaign) => { };

  return (
    <MainLayout>
      <div className="md:p-6 p-4 md:m-6 bg-white md:border md:rounded-md shadow-md">
        <div className="py-4">
          <BaeHeading>Baeonn Credits</BaeHeading>
        </div>

        <div className="pb-4">
          <BaeWarningText>
            <BaeSmallText>
              Note: Baeonn credits are not transferable to cash. And it is not
              real money.
            </BaeSmallText>
          </BaeWarningText>
        </div>

        {
          // show only if there are no credits
          credits?.length === 0 && (
            <div>
              <BaeWarningText>
                <BaeSmallText>No credits found.</BaeSmallText>
              </BaeWarningText>
            </div>
          )
        }

        {credits?.length > 0 && (
          <IfCredis
            totalCredits={totalCredits}
            totalDebits={totalDebits}
            totalBalance={totalBalance}
            setLimit={setLimit}
            limit={limit}
            setFilters={setFilters}
            filters={filters}
            credits={credits}
            handleRowClick={handleRowClick}
            pages={pages}
            setPage={setPage}
          />
        )}
      </div>
    </MainLayout>
  );
};

export default BaeonnCredits;
