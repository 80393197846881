import React from 'react';
import { BaeButton } from '../../../components/Bae';
import { useNavigate } from 'react-router-dom';

const QuickAction = () => {
  const navigate = useNavigate();
  const gotoCreateCampaign = () => navigate('/createCampaign');
  const gotoShareCoupon = () => navigate('/shareCoupons');
  const gotoClaimCoupon = () => navigate('/claimCoupons');

  return (
    <>
      <h2 className="text-black text-[22px] font-bold leading-tight tracking-[-0.015em] pb-3 pt-5">
        Quick Actions
      </h2>
      <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
        <BaeButton onClick={gotoCreateCampaign}>Create Campaign</BaeButton>
        <BaeButton onClick={gotoShareCoupon}>Share a Coupon</BaeButton>
        <BaeButton onClick={gotoClaimCoupon}>Redeem a Coupon</BaeButton>
      </div>
    </>
  );
};

export default QuickAction;
