import { useNavigate } from 'react-router-dom';
import { BaeButton, BaeHeading } from '../../../components/Bae';
import MainLayout from '../../../layout/mainLayout';
const CreateStore = () => {
  const navigate = useNavigate();
  return (
    <MainLayout>
      <div className="md:p-6 p-4 md:m-6 bg-white md:border md:rounded-md shadow-md h-screen">
        <BaeHeading>Create Store</BaeHeading>
        <div className="md:w-80 flex justify-between mt-6">
          <BaeButton
            onClick={(e) => {
              e.preventDefault();
              navigate('/create-store/add-store');
            }}
          >
            One by one
          </BaeButton>
        </div>
      </div>
    </MainLayout>
  );
};

export default CreateStore;
