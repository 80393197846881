import { useEffect, useState } from 'react';
import {
  BaeButton,
  BaeHeading,
  BaeSmallText,
  BaeTextInput,
} from '../../../../components/Bae';
import MainLayout from '../../../../layout/mainLayout';
import { claimCoupons } from '../../../../service_config/admin.service';
import { toast } from 'react-toastify';

const CouponClaimV2 = () => {
  const [disabled, setDisabled] = useState(true);
  const [couponCode, setCouponCode] = useState('');
  const [mobileNumber, setMobileNumber] = useState('');
  const [otp, setOtp] = useState('');

  useEffect(() => {
    if (
      couponCode &&
      couponCode.length > 0 &&
      mobileNumber &&
      mobileNumber.length === 10
    ) {
      setDisabled(false);
    } else {
      setDisabled(true);
    }
  }, [couponCode, mobileNumber]);

  const onSubmit = () => {
    if (disabled) return;
    claimCoupons({ coupon_code: couponCode, mobile_number: mobileNumber, otp })
      .then((response) => {
        if (response.error) {
          toast.error(response?.result?.msg);
        }
        if (!response.error) {
          toast.success(response?.result?.msg);
        }
      })
      .catch((error) => {
        toast.error(error?.result?.msg);
      });
  };

  return (
    <MainLayout>
      <div className="md:p-6 p-4 md:m-6 bg-white md:border md:rounded-md shadow-md">
        <div className="mb-4">
          <BaeHeading>Coupon Claim</BaeHeading>
        </div>

        <div className="md:flex md:items-center md:justify-center">
          <div className="md:w-96 mb-4">
            <BaeSmallText>Enter coupon code</BaeSmallText>
            <BaeTextInput
              placeholder="Coupon Code"
              label="Coupon Code"
              required={true}
              callback={(value) => setCouponCode(value)}
            />

            <div className="mt-4"></div>

            <BaeSmallText>Enter customer mobile number</BaeSmallText>
            <BaeTextInput
              placeholder="Mobile number"
              min={1000000000}
              maxLength={9999999999}
              required={true}
              callback={(value) => setMobileNumber(value)}
            />

            {/* disabled otp pls enable in backend and here */}
            {/* <BaeSmallText>OTP will be sent to above number</BaeSmallText> */}

            <div className="mt-4"></div>

            {/* disabled otp pls enable in backend and here */}
            {/* <BaeSmallText>Enter otp</BaeSmallText>
            <BaeNumberInput
              placeholder="OTP"
              callback={(value) => setOtp(value)}
            ></BaeNumberInput> */}

            {/* disabled otp pls enable in backend and here */}
            {/* <BaeButton className="mt-4" disable={disabled} onClick={onSubmit}>
              {!otp ? 'Send OTP' : 'Claim Coupon'}
            </BaeButton> */}

            <BaeButton className="mt-4" disable={disabled} onClick={onSubmit}>
              {'Claim Coupon'}
            </BaeButton>
          </div>
        </div>
      </div>
    </MainLayout>
  );
};

export default CouponClaimV2;
