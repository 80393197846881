import { useEffect, useState } from 'react';
import {
  BaeButton,
  BaeHeading,
  BaeNumberInput,
  BaeSmallText,
  BaeTab,
} from '../../../../components/Bae';
import MainLayout from '../../../../layout/mainLayout';
import {
  getCoupons,
  shareCoupons,
} from '../../../../service_config/admin.service';
import { toast } from 'react-toastify';
import BaeCoupon from '../../../../components/Bae/BaeCoupon';
import { useSelector } from 'react-redux';
import BaeDropdown from '../../../../components/Bae/BaeDropdown';
import { CAMPAIGN_TYPE } from '../../campaignCreateView/config';

const BaeCouponModal = ({ onClose, coupon = {}, store_id }) => {
  const [isSMS, setIsSMS] = useState(false);
  const [isWhatsApp, setIsWhatsApp] = useState(true);
  const [mobile, setMobile] = useState('');
  const [isActive, setIsActive] = useState(false);

  useEffect(() => {
    document.body.classList.add('overflow-hidden');
    return () => {
      document.body.classList.remove('overflow-hidden');
    };
  }, []);

  const isMobileNumber = (mobile) => {
    return !isNaN(Number(mobile));
  };

  useEffect(() => {
    if (
      mobile.length === 10 &&
      (isSMS || isWhatsApp) &&
      isMobileNumber(mobile)
    ) {
      setIsActive(true);
      return;
    }
    setIsActive(false);
  }, [isSMS, isWhatsApp, mobile]);

  const shareHandler = (e) => {
    e.preventDefault();
    const shared_via = [];
    if (isSMS) shared_via.push('sms');
    if (isWhatsApp) shared_via.push('whatsapp');

    shareCoupons({
      campaign_id: coupon.campaign_id,
      mobile_number: mobile,
      shared_via,
      request_origin: 'dashboard',
      media_partner_id: store_id,
    })
      .then((res) => {
        toast.success(res?.result?.msg);
        onClose(e);
      })
      .catch((err) => {
        toast.error(
          err?.result
            ? err?.result?.msg
            : 'Something went wrong, please try again later'
        );
      });
  };

  return (
    <div className="fixed inset-0 flex items-center justify-center z-50">
      <div
        className="fixed inset-0 bg-black opacity-50"
        onClick={onClose}
      ></div>
      <div className="bg-white p-4 rounded shadow-lg z-10 w-full max-w-lg h-full md:h-auto md:max-w-lg relative overflow-hidden">
        <div className="overflow-y-auto h-full">
          {/* close x button */}
          <div className="absolute top-0 right-0 p-4">
            <button onClick={onClose}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-6 w-6 text-gray-500"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M6 18L18 6M6 6l12 12"
                />
              </svg>
            </button>
          </div>

          <div className="text-center">
            <BaeHeading>Share Coupon</BaeHeading>
          </div>

          <div className="mt-4">
            <div className="grid grid-cols-1 md:grid-cols-2 md:text-jusitfy">
              <div>
                <div>{coupon?.title}</div>
              </div>
              <div className="mt-4 md:mt-0">
                <div>{coupon?.description}</div>
              </div>
            </div>
          </div>

          <div className="mt-4">
            <div className="grid grid-cols-1 md:grid-cols-1 gap-4">
              <div>
                <BaeSmallText>Enter customer number</BaeSmallText>
                <BaeNumberInput
                  placeholder="Mobile"
                  min={1000000000}
                  maxLength={9999999999}
                  callback={(value) => setMobile(value)}
                />
              </div>
            </div>
          </div>

          {/* disable for now share on whatsapp by default */}
          <BaeSmallText>
            Will be shared via whatsapp please confirm if user uses whatsapp on
            same number otherwise msg will not be delivered.
          </BaeSmallText>
          {/* <div className="mt-4 grid grid-cols-2 md:grid-cols-2 gap-4 justify-center items-center">
            <div className="flex flex-col items-center">
              <BaeSmallText className="block">SMS</BaeSmallText>
              <BaeToggle
                isOn={isSMS}
                onToggle={() => setIsSMS((sms) => !sms)}
              />
            </div>
            <div className="flex flex-col items-center">
              <BaeSmallText className="block">WhatsApp</BaeSmallText>
              <BaeToggle
                isOn={isWhatsApp}
                onToggle={() => setIsWhatsApp((sms) => !sms)}
              />
            </div>
          </div> */}

          <div className="mt-6">
            <div className="col-span-8">
              <BaeButton onClick={shareHandler} disable={!isActive}>
                Share
              </BaeButton>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const CouponShareV2 = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [coupons, setCoupons] = useState([]);
  const [coupon, setCoupon] = useState({});
  const stores = useSelector((state) => state.storeDetail.stores);
  const [store, setStore] = useState({});
  const [selectedCampaignType, setSelectedCampaignType] = useState(
    CAMPAIGN_TYPE.DEAL_POOL
  );

  const toggleModal = (coupon = {}) => {
    setIsModalOpen(!isModalOpen);
    setCoupon(coupon);
  };

  useEffect(() => {
    if (!store?.id) return;
    const campaignType =
      selectedCampaignType === CAMPAIGN_TYPE.DEAL_POOL
        ? 'deal_pool'
        : 'targeted';
    getCoupons(store?.id, campaignType, store?.businessId)
      .then((res) => {
        setCoupons(res?.result);
      })
      .catch((err) => {
        toast.error(err?.result?.msg);
      });
  }, [store, selectedCampaignType]);

  return (
    <MainLayout>
      <div className="md:p-6 p-4 md:m-6 bg-white md:border md:rounded-md shadow-md">
        <div>
          <BaeHeading>Coupon Share</BaeHeading>
        </div>

        <div className="mb-6 mt-6">
          <div className="md:hidden">
            <BaeTab
              tabs={[CAMPAIGN_TYPE.DEAL_POOL, CAMPAIGN_TYPE.TARGETED]}
              onSelect={(tab) => {
                setSelectedCampaignType(tab);
              }}
              isSpaced={true}
            />
          </div>
          <div className="md:block hidden">
            <BaeTab
              tabs={[CAMPAIGN_TYPE.DEAL_POOL, CAMPAIGN_TYPE.TARGETED]}
              onSelect={(tab) => {
                setSelectedCampaignType(tab);
              }}
              isSpaced={false}
            />
          </div>
        </div>
        <div className="relative">
          <BaeDropdown
            rows={stores}
            field={'branchName'}
            callback={(store) => setStore(store)}
          />
        </div>

        <div className="grid grid-cols-1 md:grid-cols-3 gap-4 mt-10">
          {!coupons.length && (
            <div className="col-span-3 text-center">
              <BaeHeading>No coupons available</BaeHeading>
            </div>
          )}

          {coupons.map((coupon) => (
            <div
              key={coupon.id}
              onClick={(e) => {
                e.preventDefault();
                toggleModal(coupon);
              }}
            >
              <BaeCoupon use={0} coupon={coupon} />
            </div>
          ))}
        </div>

        {isModalOpen && (
          <BaeCouponModal
            coupon={coupon}
            isOpen={isModalOpen}
            store_id={store?.id}
            onClose={(e) => {
              e.preventDefault();
              toggleModal({});
            }}
          />
        )}
      </div>
    </MainLayout>
  );
};

export default CouponShareV2;
