import { makeStyles } from '@material-ui/core';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import CssBaseline from '@mui/material/CssBaseline';
import Drawer from '@mui/material/Drawer';
import * as React from 'react';
import { useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import SiderBar from '../components/sideBar/sidebar';
import ProfilePopup from './ProfilePopup';
import Hamburger from 'hamburger-react';
import { getUserDetails } from '../service_config/admin.service';
const drawerWidth = 270;

const useStyles = makeStyles((theme) => ({
  image: {
    borderRadius: '3%',
  },
  caption: {
    fontSize: '0.75rem',
    lineHeight: '1rem',
    fontWeight: 'bold',
    color: '#57748a',
  },
  card: {
    maxWidth: '1000px',
    boxShadow: '0 3px 10px rgb(0 0 0 / 0.2)',
    minHeight: '100vh',
    margin: '0px auto',
    marginTop: '100px',
    borderRadius: '5px',
    padding: '20px',
  },
  btn: {
    background: 'white',
    color: 'blue',
    fontWeight: '700',
    '&:hover': {
      background: 'white',
      color: 'blue',
    },
  },
  inputfile: {
    display: 'none',
  },
  upload: {
    background: '#f0f3f5',
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    height: '100%',
    justifyContent: 'center',
  },
  icon: {
    transform: 'rotate(180deg)',
  },
  grid: {
    padding: '25px 0',
  },
  profile: {
    width: '50px',
    height: '50px',
    borderRadius: '50%',
  },
  input: {
    border: 'none',
    boxShadow: '0 3px 5px rgb(0 0 0 / 0.1)',
    background: 'white',
    '&:hover': {
      border: 'none',
      outline: 'none',
    },
  },
  isactive: {
    color: 'red',
    background: 'green',
  },
  backTo: {
    color: 'white',
    textDecoration: 'underline',
    cursor: 'pointer',
    // marginTop:"50px",
  },
}));

function ResponsiveDrawer(props) {
  const [isOpen, setOpen] = React.useState(false);

  const classes = useStyles();
  const navigate = useNavigate();
  const location = useLocation();
  const userDetail = useSelector((state) => state?.userDetail?.userDetail);
  const stores = useSelector((state) => state.storeDetail.stores);

  const logOut = () => {
    localStorage.removeItem('baeon_user');
    navigate('/login');
  };
  const navigateToHome = () => {
    navigate('/home');
  };

  React.useEffect(() => {
    getUserDetails();
  }, []);

  return (
    <Box sx={{ display: 'flex' }}>
      <CssBaseline />

      <Box
        component="nav"
        sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }}
        aria-label="mailbox folders"
      >
        {/* on desktop screen drawer and sidebar */}
        <Drawer
          variant="permanent"
          sx={{
            display: { xs: 'none', sm: 'block' },
            '& .MuiDrawer-paper': {
              boxSizing: 'border-box',
              width: drawerWidth,
            },
          }}
          open
        >
          <SiderBar />
        </Drawer>
      </Box>
      <Box
        component="main"
        className={classes.main}
        sx={{
          flexGrow: 1,
          // p: 3, // removed the content paddint - arpit
          width: { sm: `calc(100% - ${drawerWidth}px)` },
          background: '#e6f4f5',
          minHeight: '100vh',
          position: 'relative',
        }}
      >
        <div id="pageLoader" className="">
          <CircularProgress />
        </div>
        <Box
          sx={{
            width: '100%',
            background: 'rgba(2, 132, 199,1)',
            position: 'absolute',
            height: '300px',
            top: '0',
            left: '0',
            zIndex: '0',
          }}
        />
        <Box
          sx={{
            position: 'relative',
            zIndex: '1',
            width: '100%',
            minHeight: '100vh',
          }}
          className={{ padding: '0px', margin: '0px' }}
        >
          <div>
            <Box
              sx={{
                display: { xs: 'block', sm: 'none' },
              }}
            >
              <div
                className={`fixed right-5 top-5 z-40 bg-baeonn-600 rounded-lg text-white`}
              >
                <Hamburger toggled={isOpen} toggle={setOpen} />
              </div>

              {stores?.length > 0 && location?.pathname !== '/home' && (
                <div onClick={navigateToHome} className={classes.backTo}>
                  {/* Back to home */}
                </div>
              )}
              {isOpen && (
                <>
                  <Box
                    sx={{
                      display: { xs: 'block', sm: 'none' },
                      position: 'absolute',
                      top: '10',
                      zIndex: '30',
                      background: 'white',
                      '& .MuiDrawer-paper': {
                        boxSizing: 'border-box',
                        width: drawerWidth,
                      },
                    }}
                  >
                    {/* on mobile screen sidebar */}
                    <SiderBar />
                  </Box>
                </>
              )}
            </Box>
            <div className="p-4 md:block hidden">
              <ProfilePopup user={userDetail} logout={logOut} />
            </div>
          </div>

          {props.children}
        </Box>
      </Box>
    </Box>
  );
}

export default ResponsiveDrawer;
