import { useEffect, useState } from 'react';
import {
  getBussinessDetail,
  getCityOrStates,
  submitBusinessDetail,
} from '../../../service_config/admin.service';
import { toast } from 'react-toastify';
import * as yup from 'yup';
import {
  BaeButton,
  BaeCustomRadioButton,
  BaeHeading,
  BaeSmallText,
  BaeTextInput,
} from '../../../components/Bae';
import BaeDropdown from '../../../components/Bae/BaeDropdown';
import BaeDragAndDropUpload from '../../../components/Bae/BaeDragAndDropUpload';
import FileUploadHoc from '../../../components/File.Upload';

const options = ['Yes', 'No'];
const gstValidationSchema = yup.object().shape({
  state: yup.string().required('State is required'),
  exempted: yup.boolean().required('Exempted is required'),
  url: yup.string().when('exempted', {
    is: false, // When exempted is false
    then: yup.string().required('URL is required'), // URL is required
    otherwise: yup.string(), // URL is not required
  }),
  number: yup.string().when('exempted', {
    is: false, // When exempted is false
    then: yup.string().required('GST number is required'), // Number is required
    otherwise: yup.string(), // Number is not required
  }),
});

const BussinessDetail = () => {
  const [gstDetails, setGstDetails] = useState({});
  const [states, setStates] = useState([]);
  const [disabled, setDisabled] = useState(true);

  const getState = () => {
    getCityOrStates()
      .then((res) => {
        setStates(res.result);
      })
      .catch((_) => {
        toast.error('Unable to fetch states');
      });
  };

  const getDetails = () => {
    getBussinessDetail()
      .then((res) => {
        setGstDetails(res?.result?.businessDetails?.gst);
      })
      .catch((_) => {
        toast.error('Unable to fetch details');
      });
  };

  const handleFilesAdded = (location, type) => {
    if (type === 'gstDocument') {
      setGstDetails((prev) => ({
        ...prev,
        url: location,
      }));
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    submitBusinessDetail({ gst: gstDetails })
      .then((res) => {
        toast.success(res.result.msg);
      })
      .catch((_) => {
        toast.error(_.result.msg);
      });
  };

  useEffect(() => {
    gstValidationSchema
      .validate(gstDetails, { abortEarly: false })
      .then(() => {
        setDisabled(false);
      })
      .catch(() => {
        setDisabled(true);
      });
  }, [gstDetails]);

  useEffect(() => {
    getDetails();
    getState();
  }, []);

  return (
    <div className="">
      <div className="mt-4">
        <BaeHeading>Business</BaeHeading>
      </div>

      <div className="mt-4">
        <BaeSmallText className={'block mb-1'}>
          IS YOUR BUSINESS GST EXEMPTED?
        </BaeSmallText>
        <BaeCustomRadioButton
          options={options}
          callback={(v) => {
            setGstDetails({
              ...gstDetails,
              exempted: v === 'Yes',
            });
          }}
          selected={gstDetails?.exempted ? 'Yes' : 'No'}
        />
      </div>
      <div className="mt-4 mb-4 grid md:grid-cols-2 grid-cols-1 gap-4">
        <div className="md:col-span-2">
          <BaeSmallText className={'block mb-1'}>State</BaeSmallText>
          <BaeDropdown
            rows={states}
            field={'state_name'}
            selectText={'State'}
            value={gstDetails?.state}
            callback={(state) => {
              setGstDetails({
                ...gstDetails,
                state: state.state_name,
              });
            }}
          />
        </div>

        <div className="md:col-span-2 md:mt-2">
          <BaeSmallText className={'block mb-1'}>GST Number</BaeSmallText>
          <BaeTextInput
            value={gstDetails?.number}
            callback={(number) => {
              setGstDetails({ ...gstDetails, number });
            }}
          />
        </div>
      </div>
      <div>
        <BaeSmallText className={'block mb-1'}>GST Number proof</BaeSmallText>
        <FileUploadHoc
          fileType={'gstDocument'}
          set={(l, t) => handleFilesAdded(l, t)}
        >
          <BaeDragAndDropUpload
            label={'Upload GST proof'}
            fileLimit={1}
            id="gstDocument"
            image={gstDetails?.url}
          />
        </FileUploadHoc>
      </div>

      <div>
        <BaeButton className={'mt-4'} onClick={handleSubmit} disable={disabled}>
          Save
        </BaeButton>
      </div>
    </div>
  );
};

export default BussinessDetail;
