import { useState, useEffect, useRef } from 'react';
const BaeonnBgLogo = require('../../../assets/background.png');
import * as Qr from 'qrcode';

const BaeonnQR = ({ qr, size = null }) => {
  const [qrSize, setQrSize] = useState(size ? size : 150); // Default size for web view
  const qrCodeRef = useRef(null);
  const [qrImage, setQrImage] = useState(null);

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth < 640) {
        setQrSize(200); // Size for mobile view
      } else {
        setQrSize(size ? size : 150); // Size for web view
      }
    };

    // Set initial size
    handleResize();

    // Add event listener
    window.addEventListener('resize', handleResize);

    // Cleanup event listener on component unmount
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    Qr.toDataURL(qr, { type: 'png' })
      .then((img) => {
        // Then you can do whatever you want with this img
        setQrImage(img);
      })
      .catch((err) => {
        // Handle error
      });
  }, []);

  return (
    <div className="relative inline-block">
      <img src={BaeonnBgLogo} alt="BaeonnBg" className="w-full" />
      <div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2">
        {qrImage && <img src={qrImage} alt="QR Code" />}
      </div>
    </div>
  );
};

export default BaeonnQR;
