import { BaeHeading, BaeSmallText } from '.';

const BaeCoupon = ({ use = 0, coupon, noTransition = false }) => {
  const colors = ['#2ec6e2', '#156c89'];
  const text = ['#000000', '#ffffff'];
  const progress = ['#5f99d1', '#1286a8'];

  const transition = noTransition
    ? 'transform'
    : 'transition-transform transform md:hover:scale-105 cursor-pointer';

  return (
    <div className={`bg-baeonn-300 rounded-md ${transition} `}>
      <div className="grid grid-cols-12">
        {/* left for logo */}
        <div className="bg-baeonn-400 h-40 col-span-4 relative flex items-center justify-center">
          {/* brand logo */}
          <div className="grid grid-cols-1">
            <img
              src={
                coupon?.campaign_image ||
                coupon?.store?.logo ||
                'https://via.placeholder.com/150'
              }
              alt="logo"
              className="h-20 w-20 rounded-md"
            />
          </div>

          {/* top letf and bottom left cuts */}
          <div className="absolute top-0 left-0 w-8 h-8 bg-white rounded-full transform -translate-x-1/2 -translate-y-1/2"></div>
          <div className="absolute bottom-0 left-0 w-8 h-8 bg-white rounded-full transform -translate-x-1/2 translate-y-1/2"></div>
        </div>

        {/* right for content */}
        <div className="col-span-8 ml-2 h-40">
          <div className="grid grid-cols-1">
            <BaeHeading style={{ fontSize: '15px' }}>
              {coupon?.store?.name}
            </BaeHeading>
            <BaeSmallText
              className="mr-2"
              style={{ fontSize: '15px', color: 'black' }}
            >
              {coupon?.title}
            </BaeSmallText>

            <BaeSmallText style={{ color: 'black' }}>
              Expires on {coupon?.expiry_date}
            </BaeSmallText>

            <div className="h-2 mr-2 mt-1.5 bg-gray-200 rounded-full">
              <div
                className={`h-2 rounded-full bg-baeonn-500`}
                style={{ width: `${coupon?.progress_bar_percent}%` }}
              ></div>
            </div>

            <div className="text-right mr-2">
              <BaeSmallText style={{ color: 'black' }}>
                {coupon?.deals_left} Deals left
              </BaeSmallText>
            </div>

            <BaeSmallText style={{ color: 'black' }}>
              Click to share
            </BaeSmallText>
          </div>

          {/* top letf and bottom left cuts */}
          <div className="absolute top-0 right-0 w-8 h-8 bg-white rounded-full transform translate-x-1/2 -translate-y-1/2"></div>
          <div className="absolute bottom-0 right-0 w-8 h-8 bg-white rounded-full transform translate-x-1/2 translate-y-1/2"></div>
        </div>
      </div>
    </div>
  );
};

export default BaeCoupon;
