import {
  Navigate,
  Route,
  BrowserRouter as Router,
  Routes,
} from 'react-router-dom';
import './App.css';
import Register from './views/auth/register';
import { ThemeProvider } from '@mui/material/styles';
import { theme } from '../src/Theme/theme';
import component from './routes/admin.routes';
import Private from './routes/private.routes';
import TermsAndConditions from './views/TermsAncConditions';
import NearBuyStore from './views/admin/nearBuyStores';
import QRCodeGenerator from './views/auth/QRCodeGen';
import NotFound from './views/auth/pageNotFound';

function App() {
  return (
    <>
      <ThemeProvider theme={theme}>
        <Router>
          <Routes>
            <Route path="/" element={<Navigate replace to="/login" />} />
            <Route path="/login" exact element={<Register />} />
            <Route path="/nearByStores" exact element={<NearBuyStore />} />
            <Route
              path="/terms-and-conditions"
              exact
              element={<TermsAndConditions />}
            />
            <Route path="/" element={<Private />}>
              {component.map((item, i) => {
                return (
                  <Route
                    key={i}
                    path={item.path}
                    exact
                    element={item.element}
                  />
                );
              })}
            </Route>
            <Route path="*" element={<NotFound />} />
            <Route path="/generate-qr" element={<QRCodeGenerator />} />
          </Routes>
        </Router>
      </ThemeProvider>
    </>
  );
}

export default App;
