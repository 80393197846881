import { useEffect, useState } from 'react';
import {
  BaeButton,
  BaeHeading,
  BaeSmallText,
  BaeCustomRadioButton,
} from '../../../components/Bae';

import { CAMPAIGN_TYPE, COUPON_TYPE } from './config';
import { useDispatch, useSelector } from 'react-redux';
import {
  createCampaign,
  getCampaignMediaPartner,
} from '../../../service_config/admin.service';
import DealPoolCampaign from './DealPoolCampaign';
import TargetedCampaign from './TargetedCampaign';
import { toast } from 'react-toastify';
import BaeDropdown from '../../../components/Bae/BaeDropdown';
import {
  dealPoolCampaignReducer,
  targetedCampaignReducer,
} from '../../../service_config/configStore';

const CreateCampaign = ({ nextStepHandler }) => {
  const dispatch = useDispatch();
  const [selectedCampaignType, setSelectedCampaignType] = useState(
    CAMPAIGN_TYPE.DEAL_POOL
  );
  const [campaignForStore, setCampaignForStore] = useState(null);
  const stores = useSelector((state) => state.storeDetail.stores);
  const mediaPartner = useSelector(
    (state) => state.campaignAdd.campaignMediaPartners
  );
  const [isValid, setIsValid] = useState(false);
  const [campaign, setCampaign] = useState({});

  const getCreatedCampaignHandler = (campaign) => {
    if (!campaign) {
      setIsValid(false);
      setCampaign({});
    }
    if (campaign) {
      setIsValid(true);
      setCampaign(campaign);
    }
  };

  const createCampaignHandler = () => {
    if (!isValid) return;

    let data = {};

    if (CAMPAIGN_TYPE.DEAL_POOL === selectedCampaignType) {
      let tmp = {
        storeId: campaignForStore.id,
        title: campaign.campaignTitle,
        campaignType: CAMPAIGN_TYPE.DEAL_POOL,
        couponGenerateType:
          campaign.couponType === COUPON_TYPE.GENERATE
            ? 'Generate Coupon'
            : 'Predefined Coupon', // api expects this
        description: campaign.description,
        coupons: {
          total: campaign.totalCoupons,
          imageUrl: campaign?.imageUrl || '', // need to add this feature
          expiryDate: campaign.dates.expiryDate,
        },
        dealPoolCouponCode: campaign?.predefinedCoupon,
        imageUrl: campaign?.imageUrl || '', // need to add this feature
        extraText: campaign?.extraText || '', // need to add this feature
        startDate: campaign.dates.startDate,
        endDate: campaign.dates.endDate,
        campaignPincodes: campaign?.selectedStores.map(
          (store) => store.zipcode
        ),
        applicationLinks: {
          mobile: campaign.linkToApp,
          website: campaign.linkToWebsite,
        },
      };

      data = tmp;
    }

    if (selectedCampaignType === CAMPAIGN_TYPE.TARGETED) {
      let tmp = {
        storeId: campaignForStore.id,
        title: campaign.campaignTitle,
        campaignType: CAMPAIGN_TYPE.TARGETED,
        description: campaign.description,
        coupons: {
          total: campaign.totalCoupons,
          imageUrl: campaign?.imageUrl || '', // need to add this feature
          expiryDate: campaign.dates.expiryDate,
        },
        imageUrl: campaign?.imageUrl || '', // need to add this feature
        extraText: campaign?.extraText || '', // need to add this feature
        startDate: campaign.dates.startDate,
        endDate: campaign.dates.endDate,
        applicationLinks: {
          mobile: campaign.linkToApp,
          website: campaign.linkToWebsite,
        },
        mediaPartners: campaign?.selectedStores,
      };

      data = tmp;
    }

    createCampaign(data)
      .then((response) => {
        if (response && response.result) {
          if (response.error) {
            toast.error(response.result.msg);
            return;
          }
          if (!response.error) {
            toast.success(response.result.msg);
            nextStepHandler({ campaign_id: response.result.campaignId });
            /**
             * empty the store data
             * so that it does not get used in the next campaign
             */
            dispatch(targetedCampaignReducer({}));
            dispatch(dealPoolCampaignReducer({}));
          }
        }
      })
      .catch((error) => {
        if (error && error.msg) {
          toast.error(error.msg);
        }
      });
  };

  useEffect(() => {
    if (campaignForStore?.id) {
      let campaignType = 'deal_pool';
      if (selectedCampaignType === CAMPAIGN_TYPE.TARGETED) {
        campaignType = 'targeted';
      }
      getCampaignMediaPartner(campaignForStore.id, campaignType);
    }
  }, [campaignForStore?.id, selectedCampaignType]);

  return (
    <div>
      <BaeHeading size="text-4xl">Create Campaign</BaeHeading>
      <div className="py-2">
        <BaeHeading size="text-2xl">Select Store</BaeHeading>{' '}
        <BaeSmallText>
          Select store for which you want to create a campaign.
        </BaeSmallText>
        <div className="pt-2">
          <BaeDropdown
            rows={stores}
            field={'branchName'}
            callback={(store) => setCampaignForStore(store)}
          />
        </div>
      </div>

      <div className="py-2">
        <BaeHeading size="text-2xl">Campaign Type</BaeHeading>
        <div className="pt-2">
          <BaeCustomRadioButton
            options={[CAMPAIGN_TYPE.DEAL_POOL, CAMPAIGN_TYPE.TARGETED]}
            callback={(type) => setSelectedCampaignType(type)}
          />
        </div>
      </div>
      {/* campaign type depends from below */}
      {selectedCampaignType === CAMPAIGN_TYPE.DEAL_POOL ? (
        <DealPoolCampaign
          campaignForStore={campaignForStore}
          mediaPartnerStores={mediaPartner}
          createdCampaignHandler={getCreatedCampaignHandler}
        />
      ) : (
        <TargetedCampaign
          campaignForStore={campaignForStore}
          mediaPartnerStores={mediaPartner}
          createdCampaignHandler={getCreatedCampaignHandler}
        />
      )}

      <div className="pt-6 pb-2">
        <BaeButton disable={!isValid} onClick={createCampaignHandler}>
          Create Campaign
        </BaeButton>
      </div>
    </div>
  );
};

export default CreateCampaign;
